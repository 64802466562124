import * as React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store';
import * as IncidentsStore from '../../../store/incidents';
import {IncidentType} from '../../../store/incidents';
import * as CommonValuesStore from '../../../store/common-values';
import {bindActionCreators} from 'redux';
import IncidentsList from './IncidentsList';
import Loading from '../../application/Loading';
import {ConnectedRouterProps} from 'connected-react-router';
import GoBackContainer from './../../application/GoBackContainer';

// At runtime, Redux will merge together...
type IncidentsListProps =
    & IncidentsStore.IncidentsState
    & typeof IncidentsStore.actionCreators
    & CommonValuesStore.CommmonValuesState
    & typeof CommonValuesStore.actionCreators
    & ConnectedRouterProps;

class IncidentsListContainer extends React.PureComponent<IncidentsListProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    private openNewForm() {
        this.props.history.push('/incidents/new');
    }

    private openDetails(number: string) {
        this.props.history.push(`/incidents/details/${number}`);
    }

    public render() {
        return (
            <React.Fragment>
                <GoBackContainer title="Saker" onGoBack={() => this.props.history.goBack()}>
                    {this.props.isLoading ? (
                        <Loading />
                    ) : (
                            <IncidentsList incidents={this.props.incidents.filter(_ => _.type != IncidentType.Taxonomy && _.type != IncidentType.BreeamInUse)} incidentStatuses={this.props.incidentStatuses} onOpenDetails={(number) => this.openDetails(number)} onOpenNewForm={() => this.openNewForm()} />
                    )}
                </GoBackContainer>
            </React.Fragment >
        );
    }

    private ensureDataFetched() {
        this.props.ensureIncidentStatuses();
        this.props.requestIncidents();
    }
}

export default connect(
    (state: RootState) => {
        const { incidents, commonValues } = state;
        return {
            ...incidents,
            ...commonValues
        };
    },
    (dispatch) => {
        return {
            ...bindActionCreators(Object.assign({}, IncidentsStore.actionCreators, CommonValuesStore.actionCreators), dispatch)
        };
    }
)(IncidentsListContainer as any); // eslint-disable-line @typescript-eslint/no-explicit-any
